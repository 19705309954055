<div
  class="hint"
  [inlineSVG]="'/assets/images/icons/hint.svg'"
  [setSVGAttributes]="{
    height: size + 'px',
    width: size + 'px',
    fill: style === 'default' ? '#4aa956' : '#d9573f'
  }"
  [tuiHint]="translationKey | translate"
></div>
