<div class="provider-panel__body">
  <div class="provider-panel__left">
    <div class="provider-panel__amount provider-panel__amount_error">
      {{ 'errors.tradingIsNotAvailable' | translate }}
      <div
        tuiHintMode="error"
        class="provider-panel__hint provider-panel__hint_error"
        [inlineSVG]="'/assets/images/icons/hint.svg'"
        [tuiHint]="errorTranslateKey | translate"
      ></div>
    </div>
  </div>
</div>
