<div class="provider-panel__body" [ngClass]="{ 'provider-panel__body_best': isBestProvider }">
  <div
    *ngIf="isBestProvider && tradePanelData"
    class="provider-panel__best-rate"
    [ngClass]="{ 'provider-panel__best-rate_selected': providerPanelData.isSelected }"
  >
    <div [inlineSVG]="'assets/images/icons/Best-rate.svg'"></div>
  </div>

  <div class="provider-panel__left">
    <div class="provider-panel__amount" [class.tui-skeleton]="!tradePanelData">
      {{ tradePanelData?.amount | bigNumberFormat | withRound : 'toClosestValue' }}
    </div>
  </div>

  <div class="provider-panel__right">
    <div
      *ngIf="
        displayGas &&
        (this.tradePanelData?.gasFeeInEth?.isFinite() ||
          this.tradePanelData?.gasFeeInUsd?.isFinite())
      "
      class="provider-panel__token-gas-fee"
    >
      {{ 'instantTrades.gasFee' | translate }}

      <ng-container
        *ngIf="providerPanelData.appearance !== 'small' && tradePanelData.gasFeeInEth?.isFinite()"
      >
        {{ tradePanelData.gasFeeInEth | bigNumberFormat : 5 }} ETH
      </ng-container>

      <ng-container *ngIf="tradePanelData.gasFeeInUsd?.isFinite()">
        ≈ ${{ tradePanelData.gasFeeInUsd | bigNumberFormat : 2 : true }}
      </ng-container>

      <div
        class="provider-panel__hint"
        [inlineSVG]="'/assets/images/icons/hint.svg'"
        [tuiHint]="'instantTrades.gasFeeMessage' | translate"
      ></div>
    </div>
  </div>
</div>
