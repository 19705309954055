<button
  class="navbar-toggler"
  type="button"
  (click)="toggleMenu()"
  [ngClass]="{ 'navbar-toggler_opened': isMobileMenuOpened$ | async }"
  aria-label="Open menu"
>
  <span class="navbar-toggler__icon-bar"></span>
  <span class="navbar-toggler__icon-bar"></span>
  <span class="navbar-toggler__icon-bar"></span>
</button>
