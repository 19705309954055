<div class="header-settings__header">
  <div *ngIf="haveBackButton" class="header-settings__back" (click)="backClick.emit()">
    <img
      src="assets/images/icons/arrow/arrow-right.svg"
      width="7"
      height="14px"
      alt="Back to settings"
    />
  </div>
  <h3 class="header-settings__title">{{ title }}</h3>
  <img
    (click)="closeClick.emit()"
    class="header-settings__close"
    src="assets/images/icons/crosshair.svg"
    width="12"
    height="12"
    alt="Close"
  />
</div>
