<div class="modal">
  <tui-scrollbar>
    <div class="modal__content">
      <header class="modal__header" *noFrame>
        <h1 class="modal__title">{{ 'modals.walletsModal.title' | translate }}</h1>
      </header>
      <main class="modal__body">
        <ng-container *ngFor="let provider of providers">
          <ng-container *ngIf="provider.display">
            <button
              *ngIf="!(provider.value | functionCall : shouldRenderAsLink); else deepLink"
              [disabled]="(walletsLoading$ | async) || provider.disabled ? 'disabled' : false"
              class="modal__provider-button"
              (click)="connectProvider(provider.value)"
            >
              {{ provider.name }}
              <span class="modal__provider-icon"
                ><img src="{{ provider.img }}" alt="{{ provider.name }} provider"
              /></span>
            </button>
            <ng-template #deepLink>
              <a class="modal__provider-button" [href]="coinbaseDeeplink"
                >{{ provider.name }}
                <span class="modal__provider-icon"
                  ><img src="{{ provider.img }}" alt="{{ provider.name }} provider"
                /></span>
              </a>
            </ng-template>
          </ng-container>
        </ng-container>
      </main>
      <footer class="modal__footer">
        <span>{{ 'modals.walletsModal.new' | translate }} </span>
        <a target="_blank" class="modal__link" href="https://ethereum.org/en/wallets/">{{
          'modals.walletsModal.learnMore' | translate
        }}</a>
      </footer>
    </div>
  </tui-scrollbar>
</div>
