export const fiatsDictionary: Record<string, string> = {
  AUD: 'AustraliaIcon',
  GBP: 'UnitedKingdomIcon',
  USD: 'UnitedStatesIcon',
  EUR: 'EuropeanUnionIcon',
  CAD: 'CanadaIcon',
  BGN: 'BulgariaIcon',
  CHF: 'SwitzerlandIcon',
  CNY: 'ChinaIcon',
  COP: 'ColombiaIcon',
  CZK: 'CzechRepublicIcon',
  DKK: 'DenmarkIcon',
  DOP: 'DominicanRepublicIcon',
  EGP: 'EgyptIcon',
  HKD: 'HongKongIcon',
  HRK: 'CroatiaIcon',
  IDR: 'IndonesiaIcon',
  ILS: 'IsraelIcon',
  KES: 'KenyaIcon',
  LKR: 'SriLankaIcon',
  MAD: 'MoroccoIcon',
  MXN: 'MexicoIcon',
  MYR: 'MalaysiaIcon',
  NGN: 'NigeriaIcon',
  NOK: 'NorwayIcon',
  NZD: 'NewZealandIcon',
  PEN: 'PeruIcon',
  PKR: 'PakistanIcon',
  PLN: 'PolandIcon',
  RON: 'RomaniaIcon',
  RUB: 'RussiaIcon',
  SEK: 'SwedenIcon',
  SGD: 'SingaporeIcon',
  THB: 'ThailandIcon',
  TRY: 'TurkeyIcon',
  TWD: 'TaiwanIcon',
  ZAR: 'SouthAfricaIcon',
  BRL: 'BrazilIcon',
  JOD: 'JordanIcon',
  JPY: 'JapanIcon',
  KRW: 'SouthKoreaIcon',
  KWD: 'KuwaitIcon',
  OMR: 'OmanIcon',
  VND: 'VietnamIcon',
  UAH: 'UkraineIcon',
  GHS: 'GhanaIcon',
  TZS: 'TanzaniaIcon',
  UGX: 'UgandaIcon',
  INR: 'IndiaIcon',
  AED: 'UnitedArabEmiratesIcon',
  ARS: 'ArgentinaIcon',
  CLP: 'ChileIcon',
  HUF: 'HungaryIcon',
  ISK: 'IcelandIcon',
  PHP: 'PhilippinesIcon',
  KZT: 'KazakhstanIcon',
  AOA: 'AngolaIcon',
  BBD: 'BarbadosIcon',
  BZD: 'BelizeIcon',
  BMD: 'BermudaIcon',
  BND: 'BruneiIcon',
  XAF: 'CentralAfricanRepublicIcon',
  KMF: 'ComorosIcon',
  CRC: 'CostaRicaIcon',
  DJF: 'DjiboutiIcon',
  FKP: 'FalklandIslandsIcon',
  FJD: 'FijiIcon',
  GEL: 'GeorgiaIcon',
  GIP: 'GibraltarIcon',
  GTQ: 'GuatemalaIcon',
  HNL: 'HondurasIcon',
  JMD: 'JamaicaIcon',
  KGS: 'KyrgyzstanIcon',
  MWK: 'MalawiIcon',
  MRU: 'MauritaniaIcon',
  MDL: 'MoldovaIcon',
  MZN: 'MozambiqueIcon',
  PGK: 'PapuaNewGuineaIcon',
  PYG: 'ParaguayIcon',
  RWF: 'RwandaIcon',
  STN: 'SaoTomeAndPrinceIcon',
  SAR: 'SaudiArabiaIcon',
  SCR: 'SeychellesIcon',
  SBD: 'SolomonIslandsIcon',
  SRD: 'SurinameIcon',
  SZL: 'SwazilandIcon',
  TJS: 'TajikistanIcon',
  TOP: 'TongaIcon',
  TMT: 'TurkmenistanIcon',
  UYU: 'UruguayIcon',
  AZN: 'AzerbaijanIcon',
  AMD: 'ArmeniaIcon',
  BYN: 'BelarusIcon',
  MGA: 'MadagascarIcon',
  XCD: 'AntiguaAndBarbudaIcon',
  BHD: 'BahrainIcon'
};
