<a [safetyLink]="href" class="banner">
  <div class="banner__container">
    <p class="banner__text">
      {{ 'referralBanner.text' | translate }}
    </p>
    <a [safetyLink]="href" class="banner__link">
      <span class="banner__link_text">
        {{ 'referralBanner.link' | translate }}
      </span>
      <img
        src="assets/images/icons/arrow/long-arrow-right-grey.svg"
        width="20"
        height="11"
        alt="Learn more"
      />
    </a>
  </div>
</a>
