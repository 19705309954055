<section class="iframe-wallets-warning">
  <div class="iframe-wallets-warning__container">
    <h3>This wallet is not supported in {{ appearance }} frame</h3>
    <div>
      To connect this wallet go to the {{ domain }} or use Metamask wallet which supported in the
      widget
    </div>
    <div class="iframe-wallets-warning__buttons">
      <app-rubic-button fullWidth bordered (click)="onCancel()">{{
        'common.cancel' | translate
      }}</app-rubic-button>
      <a [href]="noFrameLink" rel="noopener noreferrer" target="_blank">
        <app-rubic-button fullWidth appearance="secondary">Go to {{ domain }}</app-rubic-button>
      </a>
    </div>
  </div>
</section>
