<div class="settings-list">
  <app-settings-element
    *ngFor="let item of settingsList"
    class="settings-list__item"
    [class.settings-list__item_pointer]="!!item.action"
    [title]="item.title"
    [desc]="item.description"
    (click)="item.action && item.action()"
    [component]="item.component"
    [withAction]="item.arrow"
  >
  </app-settings-element>
</div>
