<section class="modal">
  <span class="warning-text">{{ 'modals.coinbaseSelectNetworkModal.text' | translate }}</span>
  <ul class="networks">
    <li
      class="networks__item"
      [class.networks__item_selected]="blockchain === selectedBlockchain"
      *ngFor="let blockchain of availableBlockchains"
      (click)="selectedBlockchain = blockchain"
    >
      <img
        [src]="blockchainIcon[blockchain]"
        class="networks__image"
        alt="($currentBlockchain | async)?.name"
        width="25"
        height="25"
      />
      <span class="networks__label">{{ blockchainLabel[blockchain] }}</span>
    </li>
  </ul>
  <div class="buttons-container">
    <app-rubic-button appearance="secondary" fullWidth bordered (click)="onDecline()">{{
      'common.cancel' | translate
    }}</app-rubic-button>
    <app-rubic-button fullWidth appearance="primary" (click)="onConfirm()">{{
      'common.OK' | translate
    }}</app-rubic-button>
  </div>
</section>
