<button
  tuiIconButton
  [style.transform]="'scale(' + scale + ')'"
  [ngClass]="{ bordered: _border }"
  [disabled]="_disabled"
  (click)="onClick()"
  [size]="buttonSize"
  [appearance]="appearance"
>
  <img
    *ngIf="!inlineSvg"
    class="image"
    [src]="icon"
    [style.border-radius]="borderRadiusPercent + '%'"
    alt=""
  />
  <div
    *ngIf="inlineSvg"
    class="image inline-icon"
    [inlineSVG]="icon"
    [style.border-radius]="borderRadiusPercent + '%'"
  ></div>

  <div class="label-container" *ngIf="label">
    <span class="label">{{ label }}</span>
  </div>
</button>
