<ng-container>
  <span>{{
    'errors.insufficientFunds'
      | translate
        : {
            tokenSymbol: tokenSymbol,
            balance: balance,
            requiredBalance: requiredBalance
          }
  }}</span>
</ng-container>
