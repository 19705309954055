<div class="languages__list">
  <div
    class="languages__list-item"
    [class.languages__list-item_current]="language.lng === currentLanguage"
    *ngFor="let language of languagesList"
    (click)="setLanguage(language.lng)"
  >
    <img
      class="languages__image"
      [src]="'assets/images/icons/languages/' + language.lng + '.svg'"
      width="32"
      height="32"
      [alt]="language.title"
    />
    <div class="languages__title">{{ language.title }}</div>
  </div>
</div>
