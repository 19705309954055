<button
  tuiIconButton
  shape="rounded"
  [size]="size"
  tuiHintMode="onDark"
  [tuiHintDirection]="hintDirection"
  [tuiHint]="hint"
  [appearance]="appearance"
  [disabled]="_disabled"
>
  <ng-content></ng-content>
</button>
