<app-dropdown-selector
  *noFrame
  (opened)="getDropdownStatus($event)"
  [dropdownButtonTemplate]="dropdownButtonTemplate"
  [dropdownOptionsTemplates]="dropdownItems"
></app-dropdown-selector>

<ng-template #dropdownButtonTemplate>
  <div class="user-profile">
    <div class="user-profile__chain-icon" *ngIf="currentBlockchainIcon">
      <img [src]="currentBlockchainIcon" [alt]="currentBlockchainName" width="25" height="25" />
    </div>

    <div *ngLet="(currentUser$ | async)?.address as userAddress" class="user-profile__address">
      {{ userAddress.length > 20 ? (userAddress | shortAddress : 5 : 3) : userAddress }}
    </div>

    <app-notification-badge
      class="notification-counter notification-counter_profile"
      *ngIf="(unreadTrades$ | async) && (currentUser$ | async) && !dropdownIsOpened"
      size="xs"
      color="red"
      [value]="unreadTrades$ | async"
    ></app-notification-badge>

    <div class="arrow" inlineSVG="assets/images/icons/arrow-down.svg"></div>
  </div>
</ng-template>

<!--<ng-template #dropdownOptionTemplate>-->
<!--  <a class="user-profile__dropdown-item" routerLink="revoke-approval">-->
<!--    {{ 'Revoke Approval' }}-->
<!--  </a>-->
<!--</ng-template>-->

<ng-template #dropdownOptionTemplate>
  <div *noFrame class="user-profile__dropdown-item button-link" (click)="openRecentTradesModal()">
    {{ 'navigation.recentTrades' | translate }}
    <app-notification-badge
      class="notification-counter notification-counter_menu"
      *ngIf="(unreadTrades$ | async) && (currentUser$ | async) && !dropdownIsOpened"
      size="xs"
      color="red"
      [value]="unreadTrades$ | async"
    ></app-notification-badge>
  </div>
</ng-template>

<ng-template #dropdownOptionTemplate>
  <div
    *noFrame
    class="user-profile__dropdown-item button-link"
    routerLink="history/limit-orders"
    queryParamsHandling="merge"
  >
    Limit Orders
  </div>
</ng-template>

<ng-template #dropdownOptionTemplate>
  <button class="user-profile__dropdown-item user-profile__logout" (click)="logout()">
    {{ 'navigation.logout' | translate }}
  </button>
</ng-template>

<ng-container *onlyFrame>
  <div class="user-profile">
    <div class="user-profile__chain-icon" *ngIf="currentBlockchainIcon">
      <img [src]="currentBlockchainIcon" alt="Current blockchain" width="25" height="25" />
    </div>
    <div class="user-profile__address">
      {{ (currentUser$ | async)?.address | shortAddress : 5 : 3 }}
    </div>
  </div>
</ng-container>
