import { BLOCKCHAIN_NAME, BlockchainName } from 'rubic-sdk';

const basePath = 'assets/images/icons/coins/';

export const blockchainIcon: Record<BlockchainName, string> = {
  [BLOCKCHAIN_NAME.ETHEREUM]: `${basePath}eth-contrast.svg`,
  [BLOCKCHAIN_NAME.BINANCE_SMART_CHAIN]: `${basePath}bnb.svg`,
  [BLOCKCHAIN_NAME.POLYGON]: `${basePath}polygon.svg`,
  [BLOCKCHAIN_NAME.HARMONY]: `${basePath}harmony.svg`,
  [BLOCKCHAIN_NAME.AVALANCHE]: `${basePath}avalanche.svg`,
  [BLOCKCHAIN_NAME.MOONRIVER]: `${basePath}moonriver.webp`,
  [BLOCKCHAIN_NAME.FANTOM]: `${basePath}fantom.svg`,
  [BLOCKCHAIN_NAME.ARBITRUM]: `${basePath}arbitrum.svg`,
  [BLOCKCHAIN_NAME.AURORA]: `${basePath}aurora.svg`,
  [BLOCKCHAIN_NAME.TELOS]: `${basePath}telos.svg`,
  [BLOCKCHAIN_NAME.OPTIMISM]: `${basePath}optimism.png`,
  [BLOCKCHAIN_NAME.CRONOS]: `${basePath}cronos.png`,
  [BLOCKCHAIN_NAME.OKE_X_CHAIN]: `${basePath}okexchain.png`,
  [BLOCKCHAIN_NAME.GNOSIS]: `${basePath}gnosis.png`,
  [BLOCKCHAIN_NAME.FUSE]: `${basePath}fuse.png`,
  [BLOCKCHAIN_NAME.MOONBEAM]: `${basePath}moonbeam.png`,
  [BLOCKCHAIN_NAME.CELO]: `${basePath}celo.png`,
  [BLOCKCHAIN_NAME.BOBA]: `${basePath}boba.svg`,
  [BLOCKCHAIN_NAME.BOBA_BSC]: `${basePath}boba-bsc.png`,
  [BLOCKCHAIN_NAME.BOBA_AVALANCHE]: `${basePath}boba-avalanche.png`,
  [BLOCKCHAIN_NAME.ASTAR]: `${basePath}astar.svg`,
  [BLOCKCHAIN_NAME.ETHEREUM_POW]: `${basePath}eth-pow.png`,
  [BLOCKCHAIN_NAME.KAVA]: `${basePath}kava.png`,
  [BLOCKCHAIN_NAME.TRON]: `${basePath}tron.png`,
  [BLOCKCHAIN_NAME.BITCOIN]: `${basePath}bitcoin.svg`,
  [BLOCKCHAIN_NAME.SOLANA]: `${basePath}solana.svg`,
  [BLOCKCHAIN_NAME.NEAR]: `${basePath}near.svg`,
  [BLOCKCHAIN_NAME.BITGERT]: `${basePath}bitgert.png`,
  [BLOCKCHAIN_NAME.OASIS]: `${basePath}oasis.png`,
  [BLOCKCHAIN_NAME.METIS]: `${basePath}metis.svg`,
  [BLOCKCHAIN_NAME.DFK]: `${basePath}dfk.png`,
  [BLOCKCHAIN_NAME.KLAYTN]: `${basePath}klaytn.png`,
  [BLOCKCHAIN_NAME.VELAS]: `${basePath}velas.png`,
  [BLOCKCHAIN_NAME.SYSCOIN]: `${basePath}syscoin.svg`,
  [BLOCKCHAIN_NAME.EOS]: `${basePath}eos.svg`,
  [BLOCKCHAIN_NAME.ETHEREUM_CLASSIC]: `${basePath}ethereum-classic.png`,
  [BLOCKCHAIN_NAME.FILECOIN]: `${basePath}filecoin.png`,
  [BLOCKCHAIN_NAME.FLARE]: `${basePath}flare.png`,
  [BLOCKCHAIN_NAME.IOTEX]: `${basePath}iotex.png`,
  [BLOCKCHAIN_NAME.ONTOLOGY]: `${basePath}ontology.png`,
  [BLOCKCHAIN_NAME.THETA]: `${basePath}theta.png`,
  [BLOCKCHAIN_NAME.XDC]: `${basePath}xdc.png`,
  [BLOCKCHAIN_NAME.BITCOIN_CASH]: `${basePath}bitcoin-cash.svg`,
  [BLOCKCHAIN_NAME.ICP]: `${basePath}icp.svg`,
  [BLOCKCHAIN_NAME.CARDANO]: `${basePath}cardano.svg`,
  [BLOCKCHAIN_NAME.AION]: `${basePath}aion.svg`,
  [BLOCKCHAIN_NAME.ALGORAND]: `${basePath}algorand.svg`,
  [BLOCKCHAIN_NAME.APTOS]: `${basePath}aptos.svg`,
  [BLOCKCHAIN_NAME.ARDOR]: `${basePath}ardor.svg`,
  [BLOCKCHAIN_NAME.ARK]: `${basePath}ark.svg`,
  [BLOCKCHAIN_NAME.COSMOS]: `${basePath}cosmos.png`,
  [BLOCKCHAIN_NAME.BAND_PROTOCOL]: `${basePath}band-protocol.png`,
  [BLOCKCHAIN_NAME.BITCOIN_DIAMOND]: `${basePath}bitcoin-diamond.svg`,
  [BLOCKCHAIN_NAME.BSV]: `${basePath}bsv.svg`,
  [BLOCKCHAIN_NAME.BITCOIN_GOLD]: `${basePath}bitcoin-gold.svg`,
  [BLOCKCHAIN_NAME.CASPER]: `${basePath}casper.svg`,
  [BLOCKCHAIN_NAME.DASH]: `${basePath}dash.svg`,
  [BLOCKCHAIN_NAME.DECRED]: `${basePath}decred.svg`,
  [BLOCKCHAIN_NAME.DIGI_BYTE]: `${basePath}digi-byte.svg`,
  [BLOCKCHAIN_NAME.DIVI]: `${basePath}divi.svg`,
  [BLOCKCHAIN_NAME.DOGECOIN]: `${basePath}dogecoin.svg`,
  [BLOCKCHAIN_NAME.POLKADOT]: `${basePath}polkadot.svg`,
  [BLOCKCHAIN_NAME.MULTIVERS_X]: `${basePath}multiverse-x.svg`,
  [BLOCKCHAIN_NAME.FIO_PROTOCOL]: `${basePath}fio-protocol.svg`,
  [BLOCKCHAIN_NAME.FIRO]: `${basePath}firo.svg`,
  [BLOCKCHAIN_NAME.FLOW]: `${basePath}flow.svg`,
  [BLOCKCHAIN_NAME.HEDERA]: `${basePath}hedera.svg`,
  [BLOCKCHAIN_NAME.HELIUM]: `${basePath}helium.svg`,
  [BLOCKCHAIN_NAME.ICON]: `${basePath}icon.svg`,
  [BLOCKCHAIN_NAME.IOST]: `${basePath}iost.svg`,
  [BLOCKCHAIN_NAME.IOTA]: `${basePath}iota.svg`,
  [BLOCKCHAIN_NAME.KADENA]: `${basePath}kadena.svg`,
  [BLOCKCHAIN_NAME.KOMODO]: `${basePath}komodo.svg`,
  [BLOCKCHAIN_NAME.KUSAMA]: `${basePath}kusama.svg`,
  [BLOCKCHAIN_NAME.LISK]: `${basePath}lisk.svg`,
  [BLOCKCHAIN_NAME.LITECOIN]: `${basePath}litecoin.svg`,
  [BLOCKCHAIN_NAME.TERRA]: `${basePath}terra.svg`,
  [BLOCKCHAIN_NAME.TERRA_CLASSIC]: `${basePath}terra-classic.svg`,
  [BLOCKCHAIN_NAME.MINA_PROTOCOL]: `${basePath}mina-protocol.svg`,
  [BLOCKCHAIN_NAME.NANO]: `${basePath}nano.svg`,
  [BLOCKCHAIN_NAME.NEO]: `${basePath}neo.svg`,
  [BLOCKCHAIN_NAME.OSMOSIS]: `${basePath}osmosis.svg`,
  [BLOCKCHAIN_NAME.PIVX]: `${basePath}pivx.svg`,
  [BLOCKCHAIN_NAME.POLYX]: `${basePath}polyx.svg`,
  [BLOCKCHAIN_NAME.QTUM]: `${basePath}qtum.svg`,
  [BLOCKCHAIN_NAME.THOR_CHAIN]: `${basePath}thor-chain.svg`,
  [BLOCKCHAIN_NAME.RAVENCOIN]: `${basePath}ravencoin.svg`,
  [BLOCKCHAIN_NAME.SIA]: `${basePath}sia.svg`,
  [BLOCKCHAIN_NAME.SECRET]: `${basePath}secret.svg`,
  [BLOCKCHAIN_NAME.STEEM]: `${basePath}steem.svg`,
  [BLOCKCHAIN_NAME.STRATIS]: `${basePath}stratis.svg`,
  [BLOCKCHAIN_NAME.STACKS]: `${basePath}stacks.svg`,
  [BLOCKCHAIN_NAME.SOLAR]: `${basePath}solar.svg`,
  [BLOCKCHAIN_NAME.TON]: `${basePath}ton.svg`,
  [BLOCKCHAIN_NAME.VE_CHAIN]: `${basePath}ve-chain.svg`,
  [BLOCKCHAIN_NAME.WAVES]: `${basePath}waves.svg`,
  [BLOCKCHAIN_NAME.WAX]: `${basePath}wax.svg`,
  [BLOCKCHAIN_NAME.DX_CHAIN]: `${basePath}dx-chain.svg`,
  [BLOCKCHAIN_NAME.E_CASH]: `${basePath}e-cash.svg`,
  [BLOCKCHAIN_NAME.NEM]: `${basePath}nem.svg`,
  [BLOCKCHAIN_NAME.STELLAR]: `${basePath}stellar.svg`,
  [BLOCKCHAIN_NAME.MONERO]: `${basePath}monero.svg`,
  [BLOCKCHAIN_NAME.RIPPLE]: `${basePath}ripple.svg`,
  [BLOCKCHAIN_NAME.TEZOS]: `${basePath}tezos.svg`,
  [BLOCKCHAIN_NAME.VERGE]: `${basePath}verge.svg`,
  [BLOCKCHAIN_NAME.SYMBOL]: `${basePath}symbol.svg`,
  [BLOCKCHAIN_NAME.ZCASH]: `${basePath}zcash.svg`,
  [BLOCKCHAIN_NAME.HORIZEN]: `${basePath}horizen.svg`,
  [BLOCKCHAIN_NAME.ZILLIQA]: `${basePath}zilliqa.svg`
};
