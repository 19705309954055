<div class="modal">
  <div class="modal__content">
    <main class="modal__body">
      <img
        class="modal__success-image"
        src="assets/images/bridge/success-image.svg"
        alt="Success"
      />
      <h3 class="modal__title">Limit Order Submitted!</h3>
    </main>
    <footer class="modal__footer">
      <div class="modal__footer-text">
        You can check your order status in
        <span class="text-link" (click)="navigateToOrders()">Limit Orders panel</span>.
      </div>

      <app-rubic-button fullWidth appearance="primary" (click)="onConfirm()">
        {{ 'common.OK' | translate }}
      </app-rubic-button>
    </footer>
  </div>
</div>
