<div class="notification">
  <div>
    {{
      (type === 'cross-chain-routing'
        ? 'notifications.successfulCCRTradeTitle'
        : 'notifications.successfulTradeTitle'
      ) | translate
    }}
  </div>
  <ng-container *noFrame>
    <ng-container *ngIf="withRecentTrades">
      <span>{{ 'notifications.checkIn' | translate }}</span>
      <a class="notification__link" (click)="openModal()">Recent Trades</a>
    </ng-container>
  </ng-container>
</div>
