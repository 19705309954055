<tui-loader [showLoader]="loading" [overlay]="true">
  <button
    [pseudoHovered]="pseudoHovered"
    tuiButton
    [shape]="'rounded'"
    type="button"
    [size]="size"
    [appearance]="appearance"
    [class.full-width]="_fullWidth"
    [disabled]="_disabled"
    [attr.bordered]="_border"
    (click)="buttonClick($event)"
    (hoveredChange)="onHoveredChange.emit($event)"
  >
    <ng-content></ng-content>
  </button>
</tui-loader>
