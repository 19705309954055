<div class="usd-price-container" *ngIf="usdPrice" [class.usd-price-container_loading]="loading">
  <div
    *ngIf="usdPrice?.isFinite() && usdPrice.gt(0)"
    class="usd-price-container__price"
    [tuiHint]="usdPriceTooltip"
    tuiHintMode="onDark"
  >
    ${{ usdPrice | bigNumberFormat | withRound : 'toClosestValue' : { minRound: 2, maxRound: 2 } }}
  </div>
  <ng-template #usdPriceTooltip>${{ usdPrice | bigNumberFormat }}</ng-template>

  <div *ngIf="usdPrice?.isNaN()">USD Price is unavailable</div>
</div>
