<ng-container *ngIf="mode === 'table-row'; else mobile">
  <!-- Transaction date -->
  <td class="date">
    <span [class.tui-skeleton]="initialLoading">
      {{
        (uiTrade?.timestamp | date : 'MMM dd, yyyy') + ' at ' + (uiTrade?.timestamp | date : 'h:mm')
      }}
    </span>
  </td>

  <!-- Src crypto-asset -->
  <td class="from">
    <div class="asset" [class.tui-skeleton]="initialLoading">
      <img
        class="asset__image"
        [src]="uiTrade?.fromAsset?.image || defaultTokenImage"
        [alt]="uiTrade?.fromAsset?.symbol"
        (error)="onTokenImageError($event)"
      />
      <div class="asset__data">
        <div class="asset__symbol">{{ uiTrade?.fromAsset?.symbol }}</div>
        <div class="asset__asset-type">{{ fromAssetTypeName }}</div>
      </div>
    </div>
  </td>

  <!-- Status of src transaction -->
  <td class="status-from">
    <div class="status-from-wrapper" [class.tui-skeleton]="initialLoading">
      <a [href]="uiTrade?.srcTxLink" [class.hide-arrow]="!uiTrade?.srcTxLink" target="_blank">
        <app-status-badge
          [type]="uiTrade?.statusFrom | functionCall : getStatusBadgeType : this"
          [text]="getFromStatusBadgeText(uiTrade?.statusFrom) | translate"
          cursor="pointer"
        ></app-status-badge>
        <img
          class="status-from-link-arrow"
          *ngIf="!changenowId"
          src="assets/images/icons/arrow/link-arrow.svg"
          alt="link-arrow"
        />
      </a>
      <img
        *ngIf="changenowId"
        class="status-from-copy-icon"
        src="assets/images/icons/copy.svg"
        alt="Copy"
        (click)="copyToClipboard()"
        [tuiHint]="'Copy transaction ID'"
        [tuiManualHint]="template"
        [tuiManualHintShow]="hintShown"
      />
    </div>
  </td>

  <!-- Dst crypto-asset -->
  <td class="to">
    <div class="asset" [class.tui-skeleton]="initialLoading">
      <img
        class="asset__image"
        [src]="uiTrade?.toToken?.image || defaultTokenImage"
        [alt]="uiTrade?.toToken?.symbol"
        (error)="onTokenImageError($event)"
      />
      <div class="asset__data">
        <div class="asset__symbol">{{ uiTrade?.toToken?.symbol }}</div>
        <div class="asset__asset-type">
          {{ BLOCKCHAIN_LABEL?.[uiTrade?.toBlockchain] || uiTrade?.toBlockchain }}
        </div>
      </div>
    </div>
  </td>

  <!-- Status of dst transaction -->
  <td class="status-to">
    <div [class.tui-skeleton]="initialLoading">
      <ng-container *ngIf="!showRevert; else revert">
        <a
          *ngIf="!showToContinue; else toContinue"
          [href]="uiTrade?.dstTxLink"
          [class.hide-arrow]="!uiTrade?.dstTxLink"
          target="_blank"
        >
          <app-status-badge
            [type]="uiTrade?.statusTo | functionCall : getStatusBadgeType : this"
            [text]="getToStatusBadgeText(uiTrade?.statusTo) | translate"
          ></app-status-badge>
        </a>

        <ng-template #toContinue>
          <button class="status-to__continue" (click)="continueOnramperTrade()">Continue</button>
        </ng-template>
      </ng-container>
    </div>
  </td>
</ng-container>

<ng-template #mobile>
  <div class="tx">
    <div class="tx__row">
      <div class="tx__row-label">{{ 'recentTrades.date' | translate }}</div>
      <div class="tx__row-value" [class.tui-skeleton]="initialLoading">
        {{
          (uiTrade?.timestamp | date : 'MMM dd, yyyy') +
            ' at ' +
            (uiTrade?.timestamp | date : 'h:mm')
        }}
      </div>
    </div>

    <div class="tx__row">
      <div class="tx__row-label">{{ 'recentTrades.from' | translate }}</div>
      <div class="tx__row-value" [class.tui-skeleton]="initialLoading">
        <img
          class="tx__asset-image"
          [src]="uiTrade?.fromAsset?.image || defaultTokenImage"
          [alt]="uiTrade?.fromAsset?.symbol"
          (error)="onTokenImageError($event)"
        />
        <div class="tx__asset-symbol">{{ uiTrade?.fromAsset.symbol }}</div>
        <div class="tx__asset-type">{{ fromAssetTypeName }}</div>
      </div>
    </div>

    <div class="tx__row">
      <div class="tx__row-label">{{ 'recentTrades.statusFrom' | translate }}</div>
      <div class="tx__row-value" [class.tui-skeleton]="initialLoading">
        <a
          class="tx__status-from"
          [href]="uiTrade?.srcTxLink"
          target="_blank"
          [class.hide-arrow]="!uiTrade?.srcTxLink"
        >
          <app-status-badge
            [type]="uiTrade?.statusFrom | functionCall : getStatusBadgeType : this"
            [text]="getFromStatusBadgeText(uiTrade?.statusFrom) | translate"
          >
          </app-status-badge>
        </a>
      </div>
    </div>

    <div class="tx__row">
      <div class="tx__row-label">{{ 'recentTrades.to' | translate }}</div>
      <div class="tx__row-value" [class.tui-skeleton]="initialLoading">
        <img
          class="tx__asset-image"
          [src]="uiTrade?.toToken?.image || defaultTokenImage"
          [alt]="uiTrade?.toToken?.symbol"
          (error)="onTokenImageError($event)"
        />
        <div class="tx__asset-symbol">
          {{ uiTrade?.toToken.symbol }}
        </div>
        <div class="tx__asset-type">
          {{ uiTrade?.toBlockchain }}
        </div>
      </div>
    </div>

    <div class="tx__row">
      <div class="tx__row-label">{{ 'recentTrades.statusTo' | translate }}</div>
      <div class="tx__row-value" [class.tui-skeleton]="initialLoading">
        <ng-container *ngIf="!showRevert; else revert">
          <a
            *ngIf="!showToContinue; else toContinue"
            class="tx__status-to"
            [href]="uiTrade?.dstTxLink"
            target="_blank"
            [class.hide-arrow]="!uiTrade?.dstTxHash"
          >
            <app-status-badge
              [type]="uiTrade?.statusTo | functionCall : getStatusBadgeType : this"
              [text]="getToStatusBadgeText(uiTrade?.statusTo) | translate"
            ></app-status-badge>
          </a>

          <ng-template #toContinue>
            <button class="tx__to-continue" (click)="continueOnramperTrade()">Continue</button>
          </ng-template>
        </ng-container>
      </div>
    </div>
    <hr class="tx__divider" />
  </div>
</ng-template>

<ng-template #revert>
  <app-rubic-button
    appearance="primary"
    class="revert"
    (onClick)="revertTrade()"
    size="s"
    [loading]="revertBtnLoading"
  >
    {{ 'common.revert' | translate }}
  </app-rubic-button>
</ng-template>

<ng-template #template>Transaction ID copied on clipboard</ng-template>
