<ng-container *ngLet="dynamicComponent$ | async as currentComponentData">
  <tui-hosted-dropdown
    tuiDropdownAlign="left"
    [content]="dropdown"
    [(open)]="isSettingsOpened"
    (openChange)="backToSettings()"
  >
    <div class="header-settings__icon-wrapper">
      <app-rubic-button-circle class="menu-button unfocused" [size]="'l'" appearance="flat">
        <div class="settings-icon" inlineSVG="assets/images/icons/settings.svg"></div>
      </app-rubic-button-circle>
    </div>
  </tui-hosted-dropdown>

  <ng-template #dropdown>
    <aside class="header-settings">
      <app-header-settings
        [title]="currentComponentData.titleKey"
        [haveBackButton]="!this.isDefaultComponent"
        (closeClick)="closeSettings()"
        (backClick)="backToSettings()"
      ></app-header-settings>

      <div
        polymorpheus-outlet
        [content]="currentComponentData.component"
        [context]="currentComponent$"
      ></div>
    </aside>
  </ng-template>
</ng-container>
