<div class="title">{{ 'recentTrades.title' | translate }}</div>
<div class="description">{{ 'recentTrades.description' | translate }}</div>

<ng-container *ngIf="recentTrades?.length > 0; else noTransactions">
  <ng-container *ngIf="!isMobile; else mobileRecentTrades">
    <table class="table">
      <tbody>
        <tr class="table__tr table__tr_head">
          <th class="table__th">{{ 'recentTrades.date' | translate }}</th>
          <th class="table__th">{{ 'recentTrades.from' | translate }}</th>
          <th class="table__th">{{ 'recentTrades.statusFrom' | translate }}</th>
          <th class="table__th">{{ 'recentTrades.to' | translate }}</th>
          <th class="table__th">{{ 'recentTrades.statusTo' | translate }}</th>
        </tr>
        <tr
          *ngFor="let trade of recentTrades"
          trade-row
          [trade]="trade"
          mode="table-row"
          (onClose)="onClose()"
        ></tr>
      </tbody>
    </table>
  </ng-container>

  <ng-template #mobileRecentTrades>
    <div class="list">
      <div *ngFor="let trade of recentTrades" trade-row [trade]="trade" mode="mobile"></div>
    </div>
  </ng-template>
</ng-container>

<ng-template #noTransactions>
  <div class="no-transactions">
    <div class="no-transactions__title">{{ 'recentTrades.noTransactions' | translate }}</div>
    <app-rubic-button
      class="no-transactions__navigate"
      appearance="secondary"
      (onClick)="navigateToCrossChainSwaps()"
    >
      {{ 'recentTrades.swapNow' | translate }}
    </app-rubic-button>
  </div>
</ng-template>
