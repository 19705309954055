<ng-container *ngLet="isDark$ | async as isDark">
  <div
    class="image"
    [inlineSVG]="isDark ? '/assets/images/icons/night.svg' : '/assets/images/icons/day.svg'"
  ></div>

  <tui-toggle
    class="theme-toggle__switch"
    size="l"
    [ngModel]="isDark"
    (ngModelChange.stop)="switchTheme()"
  ></tui-toggle>
</ng-container>
