<tui-hosted-dropdown [content]="content" [(open)]="open" (openChange)="this.openChange($event)">
  <ng-container *ngTemplateOutlet="dropdownButtonTemplate"></ng-container>
</tui-hosted-dropdown>
<ng-template #content let-activeZone>
  <tui-data-list class="data-list" tuiDataListDropdownManager [tuiActiveZoneParent]="activeZone">
    <button
      class="data-list__option"
      tuiOption
      *ngFor="let option of dropdownOptionsTemplates; index as index"
      (click)="onOptionClick(index)"
    >
      <ng-container *ngTemplateOutlet="option"></ng-container>
    </button>
  </tui-data-list>
</ng-template>
