import { BLOCKCHAIN_NAME, BlockchainName } from 'rubic-sdk';

export const blockchainLabel: Record<BlockchainName, string> = {
  [BLOCKCHAIN_NAME.ETHEREUM]: 'Ethereum',
  [BLOCKCHAIN_NAME.BINANCE_SMART_CHAIN]: 'BNB Chain',
  [BLOCKCHAIN_NAME.POLYGON]: 'Polygon',
  [BLOCKCHAIN_NAME.HARMONY]: 'Harmony',
  [BLOCKCHAIN_NAME.AVALANCHE]: 'Avalanche',
  [BLOCKCHAIN_NAME.MOONRIVER]: 'Moonriver',
  [BLOCKCHAIN_NAME.FANTOM]: 'Fantom',
  [BLOCKCHAIN_NAME.ARBITRUM]: 'Arbitrum',
  [BLOCKCHAIN_NAME.AURORA]: 'Aurora',
  [BLOCKCHAIN_NAME.TELOS]: 'Telos EVM',
  [BLOCKCHAIN_NAME.OPTIMISM]: 'Optimism',
  [BLOCKCHAIN_NAME.CRONOS]: 'Cronos',
  [BLOCKCHAIN_NAME.OKE_X_CHAIN]: 'OKXChain',
  [BLOCKCHAIN_NAME.GNOSIS]: 'Gnosis',
  [BLOCKCHAIN_NAME.FUSE]: 'Fuse',
  [BLOCKCHAIN_NAME.MOONBEAM]: 'Moonbeam',
  [BLOCKCHAIN_NAME.CELO]: 'Celo',
  [BLOCKCHAIN_NAME.BOBA]: 'Boba',
  [BLOCKCHAIN_NAME.BOBA_BSC]: 'Boba BNB',
  [BLOCKCHAIN_NAME.BOBA_AVALANCHE]: 'Boba Avalanche',
  [BLOCKCHAIN_NAME.ASTAR]: 'Astar',
  [BLOCKCHAIN_NAME.ETHEREUM_POW]: 'Eth PoW',
  [BLOCKCHAIN_NAME.KAVA]: 'Kava',
  [BLOCKCHAIN_NAME.TRON]: 'Tron',
  [BLOCKCHAIN_NAME.BITCOIN]: 'Bitcoin',
  [BLOCKCHAIN_NAME.SOLANA]: 'Solana',
  [BLOCKCHAIN_NAME.NEAR]: 'Near',
  [BLOCKCHAIN_NAME.BITGERT]: 'Bitgert',
  [BLOCKCHAIN_NAME.OASIS]: 'Oasis',
  [BLOCKCHAIN_NAME.METIS]: 'Metis',
  [BLOCKCHAIN_NAME.DFK]: 'DFK',
  [BLOCKCHAIN_NAME.KLAYTN]: 'Klaytn',
  [BLOCKCHAIN_NAME.VELAS]: 'Velas',
  [BLOCKCHAIN_NAME.SYSCOIN]: 'Syscoin',
  [BLOCKCHAIN_NAME.EOS]: 'EOS',
  [BLOCKCHAIN_NAME.ETHEREUM_CLASSIC]: 'Eth Classic',
  [BLOCKCHAIN_NAME.FILECOIN]: 'Filecoin',
  [BLOCKCHAIN_NAME.FLARE]: 'Flare',
  [BLOCKCHAIN_NAME.IOTEX]: 'IoTeX',
  [BLOCKCHAIN_NAME.ONTOLOGY]: 'Ontology',
  [BLOCKCHAIN_NAME.THETA]: 'Theta',
  [BLOCKCHAIN_NAME.XDC]: 'XDC',
  [BLOCKCHAIN_NAME.BITCOIN_CASH]: 'Bitcoin Cash',
  [BLOCKCHAIN_NAME.ICP]: 'ICP',
  [BLOCKCHAIN_NAME.CARDANO]: 'Cardano',
  [BLOCKCHAIN_NAME.AION]: 'Aion',
  [BLOCKCHAIN_NAME.ALGORAND]: 'Algorand',
  [BLOCKCHAIN_NAME.APTOS]: 'Aptos',
  [BLOCKCHAIN_NAME.ARDOR]: 'Ardor',
  [BLOCKCHAIN_NAME.ARK]: 'Ark',
  [BLOCKCHAIN_NAME.COSMOS]: 'Cosmos',
  [BLOCKCHAIN_NAME.BAND_PROTOCOL]: 'Band Protocol',
  [BLOCKCHAIN_NAME.BITCOIN_DIAMOND]: 'Bitcoin Diamond',
  [BLOCKCHAIN_NAME.BSV]: 'Bitcoin SV',
  [BLOCKCHAIN_NAME.BITCOIN_GOLD]: 'Bitcoin Gold',
  [BLOCKCHAIN_NAME.CASPER]: 'Casper',
  [BLOCKCHAIN_NAME.DASH]: 'Dash',
  [BLOCKCHAIN_NAME.DECRED]: 'Decred',
  [BLOCKCHAIN_NAME.DIGI_BYTE]: 'DigiByte',
  [BLOCKCHAIN_NAME.DIVI]: 'Divi',
  [BLOCKCHAIN_NAME.DOGECOIN]: 'Dogecoin',
  [BLOCKCHAIN_NAME.POLKADOT]: 'Polkadot',
  [BLOCKCHAIN_NAME.MULTIVERS_X]: 'MultiversX',
  [BLOCKCHAIN_NAME.FIO_PROTOCOL]: 'FIO Protocol',
  [BLOCKCHAIN_NAME.FIRO]: 'Firo',
  [BLOCKCHAIN_NAME.FLOW]: 'Flow',
  [BLOCKCHAIN_NAME.HEDERA]: 'Hedera',
  [BLOCKCHAIN_NAME.HELIUM]: 'Helium',
  [BLOCKCHAIN_NAME.ICON]: 'ICON',
  [BLOCKCHAIN_NAME.IOST]: 'Internet of Services',
  [BLOCKCHAIN_NAME.IOTA]: 'IOTA',
  [BLOCKCHAIN_NAME.KADENA]: 'Kadena',
  [BLOCKCHAIN_NAME.KOMODO]: 'Komodo',
  [BLOCKCHAIN_NAME.KUSAMA]: 'Kusama',
  [BLOCKCHAIN_NAME.LISK]: 'Lisk',
  [BLOCKCHAIN_NAME.LITECOIN]: 'Litecoin',
  [BLOCKCHAIN_NAME.TERRA]: 'Terra',
  [BLOCKCHAIN_NAME.TERRA_CLASSIC]: 'Terra Classic',
  [BLOCKCHAIN_NAME.MINA_PROTOCOL]: 'Mina',
  [BLOCKCHAIN_NAME.NANO]: 'Nano',
  [BLOCKCHAIN_NAME.NEO]: 'Neo',
  [BLOCKCHAIN_NAME.OSMOSIS]: 'Osmosis',
  [BLOCKCHAIN_NAME.PIVX]: 'PIVX',
  [BLOCKCHAIN_NAME.POLYX]: 'Polymesh',
  [BLOCKCHAIN_NAME.QTUM]: 'QTUM',
  [BLOCKCHAIN_NAME.THOR_CHAIN]: 'THORChain',
  [BLOCKCHAIN_NAME.RAVENCOIN]: 'Ravencoin',
  [BLOCKCHAIN_NAME.SIA]: 'Siacoin',
  [BLOCKCHAIN_NAME.SECRET]: 'Secret',
  [BLOCKCHAIN_NAME.STEEM]: 'Steem',
  [BLOCKCHAIN_NAME.STRATIS]: 'Stratis',
  [BLOCKCHAIN_NAME.STACKS]: 'Stacks',
  [BLOCKCHAIN_NAME.SOLAR]: 'Solar',
  [BLOCKCHAIN_NAME.TON]: 'Toncoin',
  [BLOCKCHAIN_NAME.VE_CHAIN]: 'VeChain',
  [BLOCKCHAIN_NAME.WAVES]: 'Waves',
  [BLOCKCHAIN_NAME.WAX]: 'WAX',
  [BLOCKCHAIN_NAME.DX_CHAIN]: 'DxChain',
  [BLOCKCHAIN_NAME.E_CASH]: 'eCash',
  [BLOCKCHAIN_NAME.NEM]: 'NEM',
  [BLOCKCHAIN_NAME.STELLAR]: 'Stellar',
  [BLOCKCHAIN_NAME.MONERO]: 'Monero',
  [BLOCKCHAIN_NAME.RIPPLE]: 'Ripple',
  [BLOCKCHAIN_NAME.TEZOS]: 'Tezos',
  [BLOCKCHAIN_NAME.VERGE]: 'Verge',
  [BLOCKCHAIN_NAME.SYMBOL]: 'Symbol',
  [BLOCKCHAIN_NAME.ZCASH]: 'Zcash',
  [BLOCKCHAIN_NAME.HORIZEN]: 'Horizen',
  [BLOCKCHAIN_NAME.ZILLIQA]: 'Zilliqa'
};
